import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // The variable `canActivate` means here if the redirection is allowed or not.
    let canActivate: boolean = this.authService.isUserLoggedIn();
    const isUrl = paths => paths.some(path => path === route.routeConfig.path);
    const unrestrictedUrls = ['login', 'reset-password', 'change-password'];

    if (!canActivate && !isUrl(unrestrictedUrls)) {
      this.router.navigateByUrl('/auth/login');
    } else if (canActivate && isUrl(unrestrictedUrls)) {
      // We redirect the authentication pages to the pairing page if the token in the local storage is already valid.
      this.router.navigateByUrl('/main/pairing');
    } else {
      canActivate = true;
    }
    return canActivate;
  }
}
