import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthService} from '../shared/service/auth.service';
import {UserService} from '../shared/service/user.service';
import {takeUntil} from 'rxjs/operators';
import {I18nTranslations} from '../shared/i18n/i18n-translations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  isLoggedIn = false;

  private componentDestroy: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService, private userService: UserService) {
  }

  ngAfterViewInit(): void {
    this.isLoggedIn = this.authService.isUserLoggedIn();
    this.authService.loggedInSubject
      .asObservable()
      .pipe(takeUntil(this.componentDestroy))
      .subscribe(res => {
        this.isLoggedIn = res;
        this.refreshUserSettings();
      });

    this.refreshUserSettings();
  }

  ngOnDestroy(): void {
    this.componentDestroy.next(true);
    this.componentDestroy.complete();
  }

  private refreshUserSettings(): void {
    if (this.authService.isUserLoggedIn()) {
      this.userService.getUserSettings()
        .pipe(takeUntil(this.componentDestroy))
        .subscribe(userSettings => {
          const siteLocale = I18nTranslations.getSiteLocale();
          if (siteLocale !== userSettings.language) {
            window.location.href = `/${userSettings.language}/`;
          }
        });
    }
  }
}
