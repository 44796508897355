import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BannerComponent} from 'src/shared/component/banner/banner.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthService} from 'src/shared/service/auth.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from 'src/shared/guard/auth.guard';
import {AuthInterceptor} from 'src/shared/interceptor/auth.interceptor';
import {RouterModule} from '@angular/router';
import {CompanyInterceptor} from 'src/shared/interceptor/company.interceptor';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomPaginator} from '../shared/config/custom-paginator-config';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompanyInterceptor,
      multi: true
    },
    {provide: MatPaginatorIntl, useValue: CustomPaginator()}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
