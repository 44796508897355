import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UrlService} from './url.service';
import jwt_decode from 'jwt-decode';
import {I18nTranslations} from '../i18n/i18n-translations';
import {UserService} from './user.service';

@Injectable()
export class AuthService {

  static TOKEN_KEY = 'jwtToken';
  private static REFRESH_TOKEN_KEY = 'jwtRefreshToken';
  private static APPLICATION_KEY = 'AWA';

  loggedInSubject = new Subject<boolean>();

  constructor(private http: HttpClient, private userService: UserService, private urlService: UrlService) {
  }

  refreshToken(): Observable<any> {
    return this.http.post<any>(this.urlService.URL_REFRESH_TOKEN, {refreshToken: this.getRefreshToken()}, {observe: 'response'})
      .pipe(tap((res: any) => {
        this.store(res.body.jwtToken, AuthService.TOKEN_KEY);
      }));
  }

  logIn({email, password}): Observable<any> {
    return this.http
      .post<any>(this.urlService.URL_LOGIN, {email, password, application: AuthService.APPLICATION_KEY})
      .pipe(tap((res: any) => {
        this
          .store(res.jwtToken, AuthService.TOKEN_KEY)
          .store(res.jwtRefreshToken, AuthService.REFRESH_TOKEN_KEY);

        const siteLocale = I18nTranslations.getSiteLocale();
        this.userService.setUserSettings(siteLocale).subscribe();

        this.loggedInSubject.next(true);
      }));
  }

  logOut(): Observable<any> {
    return this.http
      .post<any>(this.urlService.URL_LOGOUT, {refreshToken: this.getRefreshToken()})
      .pipe(tap((res: any) => {
        this.clear();
        this.loggedInSubject.next(false);
      }));
  }

  resetPassword({email}): Observable<any> {
    return this.http.post<any>(this.urlService.URL_RESET_PASSWORD, {
      email,
      application: AuthService.APPLICATION_KEY,
      redirectUrl: window.location.origin + '/auth/change-password'
    });
  }

  changePassword({email, password = null, newPassword, token = null}): Observable<any> {
    return this.http.post<any>(this.urlService.URL_CHANGE_PASSWORD, {
      email,
      newPassword,
      password,
      application: AuthService.APPLICATION_KEY,
      token
    });
  }

  store(token, key): AuthService {
    if (token !== localStorage.getItem(key)) {
      localStorage.setItem(key, token);
    }
    return this;
  }

  getToken(): string {
    return localStorage.getItem(AuthService.TOKEN_KEY);
  }

  /** Check if user has role to access application */
  hasMainRole(): boolean {
    return this.getRoles().includes('ROLE_AWA');
  }

  /** Returns list of accessible companies depending on roles */
  getAccessibleCompanies(): string[] {
    return this.getRoles()
      .filter(role => role.includes('AWA_'))
      .map(role => role.substring(9));
  }

  getRefreshToken(): string {
    return localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
  }

  clear(): void {
    localStorage.removeItem(AuthService.TOKEN_KEY);
    localStorage.removeItem(AuthService.REFRESH_TOKEN_KEY);
  }

  isUserLoggedIn(): boolean {
    return null !== this.getToken();
  }

  private getRoles(): string[] {
    return jwt_decode(this.getToken())['ROLES'].split(',');
  }
}
