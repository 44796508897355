import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  API = 'awa';

  private V1 = 'v1';

  private url = (module, route, version): string => `/api/${module}/rest/${version}/${route}`;
  private authUrlV1 = (route): string => this.url('auth', route, this.V1);
  private apiUrlV1 = (route): string => this.url(this.API, route, this.V1);

  URL_LOGIN = this.authUrlV1('login');
  URL_LOGOUT = this.authUrlV1('log-out');
  URL_REFRESH_TOKEN = this.authUrlV1('refresh');
  URL_RESET_PASSWORD = this.authUrlV1('reset-password');
  URL_CHANGE_PASSWORD = this.authUrlV1('change-password');

  URL_BIKE_MODELS = this.apiUrlV1('models');
  URL_PAIRING = this.apiUrlV1('pairings');
  URL_PAIRING_VEHICLE_NUMBER_VALIDITY = (vehicleNumber) => this.apiUrlV1(`pairings/vehicleNumber/${vehicleNumber}/validity`);
  URL_PAIRING_SERIAL_NUMBER_VALIDITY = (serialNumber) => this.apiUrlV1(`pairings/serialNumber/${serialNumber}/validity`);
  URL_PRODUCTS = this.apiUrlV1('products');
  URL_PRODUCTS_EXPORT = this.apiUrlV1('products/export');
  URL_TESTS = this.apiUrlV1('tests');
  URL_OCODE_FNUCI = vehicleNumber => this.apiUrlV1(`ocode/${vehicleNumber}/fnuci`);

  URL_USER_SETTINGS = () => this.apiUrlV1(`user/settings`);

  insertToken = (url) => ![this.URL_LOGIN, this.URL_REFRESH_TOKEN].includes(url) && !this.externalUrl(url);

  externalUrl = (url) => url.startsWith('http');
}
