export class I18nTranslations {
  static COMPANY_MARK = $localize`Entreprise / Marque`;

  static PAIRED_TO_USER_ERROR = $localize`Le désappairage n\'est pas autorisé tant que le vélo est associé à un utilisateur.`;
  static GLOBAL_ERROR = $localize`Une erreur est survenue, veuillez contacter l\'administrateur système.`;

  static PAIRING = $localize`appairage`;
  static INTEGRATION_TEST = $localize`tests`;
  static PRODUCTS = $localize`produits`;

  static getSiteLocale(): 'fr' | 'en' | 'de' {
    const localePath = window.location.pathname.split('/')[1];
    return (localePath === 'en' || localePath === 'de') ? localePath : 'fr';
  }
}
