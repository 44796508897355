import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from '../../service/auth.service';
import {I18nTranslations} from '../../i18n/i18n-translations';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  isLogged = false;
  selectedLang: 'fr' | 'en' | 'de';

  constructor(private authService: AuthService, private router: Router) {
    this.selectedLang = I18nTranslations.getSiteLocale();
  }

  ngOnInit(): void {
    this.isLogged = this.authService.isUserLoggedIn();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(_ => this.isLogged = this.authService.isUserLoggedIn());
  }

  logOut(): void {
    this.authService.logOut()
      .subscribe(_ => this.router.navigateByUrl('auth/login'));
  }

  changeLang(lang: 'fr' | 'en' | 'de'): void {
    this.selectedLang = lang;

    const siteLocale = I18nTranslations.getSiteLocale();
    if (siteLocale !== this.selectedLang) {
      window.location.href = `/${this.selectedLang}/`;
    }
  }
}
