import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UrlService} from './url.service';
import {UserSettings} from '../model/user-settings';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private urlService: UrlService) {
  }

  getUserSettings(): Observable<UserSettings> {
    return this.http.get<UserSettings>(this.urlService.URL_USER_SETTINGS());
  }

  setUserSettings(language: string): Observable<void> {
    const headers = new HttpHeaders();

    return this.http.patch<void>(this.urlService.URL_USER_SETTINGS(), language, {headers});
  }
}
