import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private route: ActivatedRoute) {
  }

  get company(): string {
    return this.route.snapshot.queryParamMap.get('company');
  }

}
