<div class="banner">
  <div class="logo" [class.logo-left]="isLogged">
    <img src="assets/Logo_velco_blanc.png" height="100%"/>
  </div>
  <div class="actions" *ngIf="isLogged">
    <span class="cursor-pointer" (click)="logOut()" i18n>Déconnexion</span>
  </div>

  <div class="lang-container" *ngIf="!isLogged">
    <img src="assets/french.svg" [class.unselected]="selectedLang !== 'fr'"
         alt="french" (click)="changeLang('fr')">
    <img src="assets/english.svg" [class.unselected]="selectedLang !== 'en'"
         alt="english" (click)="changeLang('en')">
    <img src="assets/german.svg" [class.unselected]="selectedLang !== 'de'"
         alt="german" (click)="changeLang('de')">
  </div>
</div>
