import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CompanyService} from '../service/company.service';
import {UrlService} from '../service/url.service';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {

  constructor(private companyService: CompanyService, private urlService: UrlService) {
  }

  /** Add company to request if it is adressed to api */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.urlService.API)) {
      req = req.clone({
        setParams: {
          company: this.companyService.company
        }
      });
    }
    return next.handle(req);
  }
}
